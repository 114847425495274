<template>
    <div class="home">
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title is-4">{{ $t("Interface.Key.Invites.Title") }}</h1>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <router-link
                        :to="{ name: 'Key-Share', params: { id: $route.params.id } }"
                        class="button is-theme"
                    >
                        {{ $t("Interface.Key.Share.Title") }}
                    </router-link>
                </div>
            </div>
        </div>
        <b-table
            :data="Invites"
            :striped="true"
            :mobile-cards="true"
            :paginated="true"
            :per-page="ItemsPerPage"
            :current-page.sync="PageNumber"
            :total="InvitesAmount"
            :loading="IsLoading"
            default-sort="id"
            icon-pack="fa"
        >
            <b-table-column v-slot="props" field="email" :label="$t('Interface.Settings.Account.Email')" sortable>
                {{ props.row.email }}
            </b-table-column>
            <b-table-column v-slot="props" field="phone" :label="$t('Interface.Settings.Account.Phone')" sortable>
                {{ props.row.phone }}
            </b-table-column>
            <b-table-column v-slot="props" field="userName" :label="$t('Interface.Log.UserName')" sortable>
                {{ props.row.userName }}
            </b-table-column>
            <b-table-column v-slot="props" field="lastEmailSent" :label="$t('Interface.Key.Invites.LastEmailSent')" sortable>
                {{ filterDateTime(props.row.lastEmailSent) }}
            </b-table-column>
            <b-table-column v-slot="props" field="used" :label="$t('Interface.Key.Invites.Used.Title')" sortable>
                <template v-if="props.row.used">
                    {{ $t("Interface.Key.Invites.Used.True") }}
                </template>
                <template v-else>
                    {{ $t("Interface.Key.Invites.Used.False") }}
                </template>
            </b-table-column>
            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>{{ $t("Interface.Door.List.Empty") }}</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import Helpers from "@/utils/Helpers"
import LoadingState from "@/mixins/LoadingState"
import {
    KEY_INVITES_REQUEST
} from "@/store/actions/key"

export default {
    name: "KeyInvites",
    mixins: [LoadingState],
    data() {
        return {
            PageNumber: 1,
            ItemsPerPage: 10
        }
    },
    computed: {
        InvitesAmount() {
            return this.$store.getters.INVITES_AMOUNT
        },
        Invites() {
            return this.$store.state.Key.Invites
        }
    },
    mounted() {
        this.loadInvites()
    },
    methods: {
        loadInvites() {
            this.switchLoading()
            this.$store
                .dispatch(KEY_INVITES_REQUEST, this.$route.params.id)
                .finally(() => this.switchLoading())
        },
        filterDateTime(date) {
            return Helpers.filterDateTime(date)
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Key.Invites.Title")
        }
    }
}
</script>
